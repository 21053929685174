import { Component, OnDestroy, OnInit } from "@angular/core";
import { AtlasBaseMapComponent } from "../atlas-base-map.component";
import { Feature, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { HttpClient } from "@angular/common/http";
import { BootstrapMediaService } from "src/app/services/bootstrap-media.service";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import GeoJSON from "ol/format/GeoJSON";
import { OLMapService } from 'src/app/services/ol-map/ol-map.service';
import { AtlasMobileFilterService } from 'src/app/services/filter/atlas-mobile-filter.service';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { unsubscribe } from 'src/app/utils/helpers';

@Component({
  selector: "app-atlas-mobile-map",
  templateUrl: "./atlas-mobile-map.component.html",
  styleUrls: ["./atlas-mobile-map.component.scss"],
})
export class AtlasMobileMapComponent extends AtlasBaseMapComponent implements OnInit, OnDestroy {
  private mapSubscriptions = new Subscription();
  constructor(
    private atlasMobileFilterService: AtlasMobileFilterService,
    protected http: HttpClient,
    protected breakpointObserver: BootstrapMediaService,
    protected translationHelper: TranslationHelperService,
    public olMapService: OLMapService
  ) {
    super(http, breakpointObserver, translationHelper, olMapService);
  }

  ngOnInit() {
    this.subscriptions.push(combineLatest([this.atlasMobileFilterService.atlasMobileService.detailedSubject, this.atlasMobileFilterService.atlasMobileService.byzoneSubject])
      .subscribe((val) => {
        if (val.some((v) => v == false)) {
          return
        }
        this.atlasMobileFilterService.setQueryParams()
        this.atlasMobileFilterService.atlasMobileService.setDefaultFilter();
        this.loadLayer();
      }))
    this.atlasMobileFilterService.atlasMobileService.getLegends("mobile-legend");
    this.atlasMobileFilterService.atlasMobileService.getFilter("mobile-filter");
    this.atlasMobileFilterService.atlasMobileService.getDetails("mobile-detail");
    this.mapOperations();

  }

  mapOperations(): void {
    this.atlasMobileFilterService.atlasMobileService.eventsKey = this.olMapService.map.on("singleclick", (event: MapBrowserEvent<MouseEvent>) => {
      const joinEndpoints = this.atlasMobileFilterService.atlasMobileService.mapOperations(event);
      if (!joinEndpoints || !Array.isArray(joinEndpoints) || joinEndpoints.length === 0) {
        return;
      }
  
      this.mapSubscriptions.add(
        forkJoin(joinEndpoints).subscribe((featureCollections: GeoJSON[]) => {
          featureCollections.forEach((featureCollection: GeoJSON) => {
            const format = new GeoJSON();
            const features: Feature<Geometry>[] = format.readFeatures(featureCollection);
            if (features.length > 0) {
              this.updateDetails(features[0]);
              this.addVectorLayer(features[0], format);
            }
          });
        })
      );
    });
  }  
  getLayerDetails(feature: Feature<Geometry>): void {
    this.atlasMobileFilterService.atlasMobileService.getLayerDetails(feature);
  }
  getLayerName(): string {
    return this.atlasMobileFilterService.atlasMobileService.getLayerName();
  }
  getStyleName(): string {
    return this.atlasMobileFilterService.atlasMobileService.getStyleName();
  }
  loadLayer() {
    this.atlasMobileFilterService.atlasMobileService.loadLayer();
  }
  setFilter() {
    this.atlasMobileFilterService.atlasMobileService.setFilter();
  }
  updateDetails(feature: Feature<Geometry>) {
    this.atlasMobileFilterService.atlasMobileService.updateDetails(feature);
  }
  addVectorLayer(feature: Feature<Geometry>, format: GeoJSON) {
    this.atlasMobileFilterService.atlasMobileService.addVectorLayer(feature, format)
  }
  ngOnDestroy(): void {
    this.mapSubscriptions.unsubscribe()
    this.atlasMobileFilterService.atlasMobileService.mapOperationsUn();
    unsubscribe(this.subscriptions)
  }
}
