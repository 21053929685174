import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  DEFAULT_TREIN_FILTERS,
  TREIN_FILTERS,
} from "src/assets/constants/constants";

@Injectable({
  providedIn: "root",
})
export class TreinFormService {
  private _form: FormGroup;

  get form() {
    if (!this._form) {
      this.initForm();
    }
    return this._form;
  }

  private initForm() {
    if (this.checkQueryParams()) {
      let filterForm: any = {};
      TREIN_FILTERS.forEach((key) => {
        filterForm[key] = new FormControl(this.route.snapshot.queryParams[key]);
      });
      this._form = this.fb.group(filterForm);
    } else {
      let filterForm: any = {};
      TREIN_FILTERS.forEach((key) => {
        filterForm[key] = new FormControl(DEFAULT_TREIN_FILTERS[key]);
      });
      this._form = this.fb.group(filterForm);
    }
    }
  private checkQueryParams() {
    return TREIN_FILTERS.every((key) => !!this.route.snapshot.queryParams[key]);
  }
  constructor(private fb: FormBuilder, private route: ActivatedRoute) {}
}
