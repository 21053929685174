import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataSourceDescriptionData } from "src/app/models/data-source-description-data.model";
import { AbstractPresentationPage } from "src/app/pages/abstract-presentation-page/abstract-presentation-page";
import { Area } from "src/app/services/area/area";
import { MetadataService } from "src/app/services/metadata.service";
import { OLMapService } from "src/app/services/ol-map/ol-map.service";
import {
  PROJECT_DESCRIPTIONS,
  ProjectService,
} from "src/app/services/project.service";
import { StatisticsService } from "src/app/services/statistics/statistics.service";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import { unsubscribe } from "src/app/utils/helpers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-atlas-trein-page",
  templateUrl: "atlas-trein-page.component.html",
  styleUrls: ["./atlas-trein-page.component.scss"],
})
export class AtlasTreinPageComponent
  extends AbstractPresentationPage
  implements OnInit
{
  dataDescription: DataSourceDescriptionData = PROJECT_DESCRIPTIONS.atlasTrein;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    protected statistics: StatisticsService,
    protected metadata: MetadataService,
    protected translationHelper: TranslationHelperService,
    public olMapService: OLMapService,
    private projectService: ProjectService
  ) {
    super(statistics);
    const group = projectService.groups.filter((c) => c.group == "Atlas")[0];
    projectService.selectGroup(group);
    this.metadata.setImage(
      environment.baseHref + "assets/images/og/atlas-mobile3.png"
    );
    this.metadata.setSubtitle("ui.projects.atlastrein.title");
    this.metadata.setDescription("ui.projects.atlastrein.description");
  }
  ngOnInit(): void {
    //this.generateTreinFilterOptions();
  }
  // generateTreinFilterOptions() {
  //   this.options$ = this.geoService.getTreinmetingenCapabilities().pipe(map((response) => {
  //     const capabilities = this.parser.read(response);
  //     const layer = capabilities.Capability.Layer.Layer.find(l => l.Name === "treinmetingen");
  //     if(layer && layer.Abstract) {
  //       const optionsDict = JSON.parse(layer.Abstract)
  //       this.formService.initForm(optionsDict['dropdown_options'],optionsDict['dropdown_names'])
  //       this.updateRoute(this.form.value);
  //       return optionsDict
  //     }
  //     else{
  //       return [];
  //     }
  //   }))
  //   .pipe(
  //     tap(() =>
  //       this.form.valueChanges.subscribe((value) => {
  //         this.updateRoute(value);
  //       })
  //     )
  //   )
  // }
  searchArea(area: Area) {
    this.olMapService.searchArea(area);
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
