import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import { Observable } from "rxjs";
import { ApiFilterValue } from "src/app/datasets/api-filter-value.model";
import { TREIN_FILTERS, TREIN_LAYER_NAME, TREIN_WORKSPACE } from "src/assets/constants/constants";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GeoserverAPIService {
  constructor(private http: HttpClient) {}
  getTreinFeatures(): Observable<GeoJSONFeatureCollection>{
    const propertyName = TREIN_FILTERS.join(',');
    return this.http.get<GeoJSONFeatureCollection>(
      `${environment.geoServer.baseUrl}/${TREIN_WORKSPACE}/wfs?service=WFS&version=2.0.0&request=GetFeature&typeNames=${TREIN_WORKSPACE}%3A${TREIN_LAYER_NAME}&propertyName=${propertyName}&outputFormat=application/json`,
      {responseType:"json"}
    );
  }

  getMobileDetailedLayerNames(): Observable<any> {
    return this.http.get(
      `${environment.geoServer.baseUrl}/${environment.geoServer.dp_mobile_detailed_overview}/wfs?service=WFS&version=2.0.0&request=GetCapabilities`,
      { responseType: "text" }
    );
  }

  getMobileByZoneLayerNames(): Observable<any> {
    return this.http.get(
      `${environment.geoServer.baseUrl}/${environment.geoServer.dp_mobile_byzone_overview}/wfs?service=WFS&version=2.0.0&request=GetCapabilities`,
      { responseType: "text" }
    );
  }

  getLandLineCapabilities(): Observable<any> {
    return this.http.get(
      `${environment.geoServer.baseUrl}/${environment.geoServer.dp_landline_overview}/wfs?service=WFS&version=2.0.0&request=GetCapabilities`,
      { responseType: "text" }
    );
  }

  getTreinmetingenCapabilities(): Observable<any> {
    return this.http.get(
      `${environment.geoServer.baseUrl}/${environment.geoServer.dp_treinmetingen_overview}/wfs?service=WMS&version=2.0.0&request=GetCapabilities&typeNames=${TREIN_WORKSPACE}%3A${TREIN_LAYER_NAME}&outputFormat=application/json`,
      { responseType: "text" }
    );
  }

  getLandLineDescribeFeatures(typeName: string): Observable<any> {
    return this.http.get<any>(
      `${environment.geoServer.baseUrl}/wfs?service=WFS&version=2.0.0&request=DescribeFeatureType&outputFormat=application/json&typeNames=${typeName}`
    );
  }

  getLandlinePolygonNames(datastoreSuffix: string): Observable<ApiFilterValue> {
    return this.http.get<ApiFilterValue>(
      `${environment.geoServer.baseUrl}/wfs?service=WFS&version=2.0.0&request=DescribeFeatureType&typeNames=`
    );
  }
  getLandlinePointNames(datastoreSuffix: string): Observable<ApiFilterValue> {
    return this.http.get<ApiFilterValue>(
      environment.GeoServer.api.landlineUrl +
        "?datastoreSuffix=" +
        datastoreSuffix
    );
  }
}
