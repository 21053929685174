import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import TileWMS from "ol/source/TileWMS";
import ImageWMS from 'ol/source/ImageWMS';

import { OlAtlasBaseService } from "src/app/services/ol-map/ol-atlas-base.service";
import { Feature, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { OLMapService } from "src/app/services/ol-map/ol-map.service";
import {
  LABEL_TREIN,
  TREIN_GROUP_NAME,
  TREIN_HEAT_NAME,
  TREIN_LAYER_NAME,
  TREIN_WORKSPACE,
} from "src/assets/constants/constants";
import { TreinFormService } from "./trein-form.service";

@Injectable({
  providedIn: "root",
})
export class TreinMapService extends OlAtlasBaseService {
  constructor(
    private http: HttpClient,
    public olMapService: OLMapService,
    public treinFormService: TreinFormService
  ) {
    super(olMapService);
  }
  loadLayer() {
    this.removeVectorLayers();
    const cql_filter = Object.keys(this.treinFormService.form.value)
      .filter((key) => this.treinFormService.form.value[key])
      .map((key) => `${key}='${this.treinFormService.form.value[key]}'`)
      .join(" AND ");

    const existingTileLayer = this.olMapService.layers.find((l) => {
      if (l.name !== TREIN_LAYER_NAME) {
        return false;
      }

      const source = l.layer.getSource();
      return (
        source instanceof TileWMS && 
        source.getParams().CQL_FILTER === cql_filter
      );
    });

    const existingImageLayer = this.olMapService.layers.find((l) => {
      if (l.name !== TREIN_LAYER_NAME) {
        return false;
      }

      const source = l.layer.getSource();
      return (
        source instanceof ImageWMS && 
        source.getParams().CQL_FILTER === cql_filter
      );
    });

    if (existingTileLayer === undefined) {
      const tileLayer = new TileLayer({
        source: new TileWMS({
          url: `${environment.geoServer.baseUrl}/${TREIN_WORKSPACE}/wms`,
          params: {
            LAYERS: `${TREIN_WORKSPACE}:${TREIN_LAYER_NAME}`,
            FORMAT: "image/png",
            CQL_FILTER: cql_filter,
          },
        }),
        //opacity: 0.6,
      });
      this.olMapService.map.addLayer(tileLayer);
      this.olMapService.layers.push({
        name: TREIN_LAYER_NAME,
        layer: tileLayer,
      });
    } if(existingTileLayer != undefined) {
      existingTileLayer.layer.setVisible(true);
      if (
        !this.olMapService.map
          .getLayers()
          .getArray()
          .includes(existingTileLayer.layer)
      ) {
        this.olMapService.map.addLayer(existingTileLayer.layer);
      }
    }
    if (existingImageLayer === undefined) {
      const imageLayer = new ImageLayer({
        source: new ImageWMS({
          url: `${environment.geoServer.baseUrl}/${TREIN_WORKSPACE}/wms`,
          params: {
            LAYERS: `${TREIN_WORKSPACE}:${TREIN_HEAT_NAME}`,
            FORMAT: "image/png",
            CQL_FILTER: cql_filter,
          },
        }),
        //opacity: 0.6,
      });
      this.olMapService.map.addLayer(imageLayer);
      this.olMapService.layers.push({
        name: TREIN_LAYER_NAME,
        layer: imageLayer,
      });
    } if(existingImageLayer != undefined) {
      existingImageLayer.layer.setVisible(true);
      if (
        !this.olMapService.map
          .getLayers()
          .getArray()
          .includes(existingImageLayer.layer)
      ) {
        this.olMapService.map.addLayer(existingImageLayer.layer);
      }
    }

    this.olMapService.layers.forEach((visibleLayer) => {
      const source = visibleLayer.layer.getSource();
      const isTileWMS = source instanceof TileWMS || source instanceof ImageWMS;
      const matchesCqlFilter =
        isTileWMS && source.getParams().CQL_FILTER === cql_filter;
      if (visibleLayer.name !== TREIN_LAYER_NAME || !matchesCqlFilter) {
        visibleLayer.layer.setVisible(false);
      }
    });
  }

  updateDetails(feature: Feature<Geometry>) {
    this.olMapService.detailsLoading = true;
    this.getLayerDetails(feature);
    this.olMapService.detailsLoading = false;
  }

  getLayerDetails(feature: Feature<Geometry>): void {
    this.olMapService.detailsLoading = true;
    const keys = feature.getKeys();
    if (keys.length > 0) {
      feature
        .getKeys()
        .sort()
        .forEach((key) => {
          if (
            LABEL_TREIN.includes(key) &&
            feature.get(key) !== "" &&
            feature.get(key) !== null
          ) {
            this.olMapService.details.push({
              label: key,
              value: this.toPercentage(feature.get(key)),
            });
          }
        });
    }
    this.olMapService.detailsLoading = false;
  }
  /**
   * This method checks if the value is a number and whether it's a float number. If both conditions are true,
   * it returns the decimal value with a percentage symbol;
   * otherwise, it simply returns the value unchanged.
   */
  toPercentage(value: string): string {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue <= 1) {
      return Math.round(numericValue * 100) + '%';
    }
    return value;
  }
  mapOperations(event: MapBrowserEvent<MouseEvent>) {
    this.olMapService.details = [];
    this.removeVectorLayers();
    return this.createJoinEndpoints(event);
  }
  createJoinEndpoints(
    event: MapBrowserEvent<MouseEvent>
  ): Observable<Object>[] {
    const tileLayers = event.map
      .getLayers()
      .getArray()
      .filter(
        (l) =>
          l instanceof TileLayer &&
          l.getSource() instanceof TileWMS &&
          l.getVisible()
      ) as TileLayer<TileWMS>[];

    if (!tileLayers) {
      return;
    }
    const tileSources = tileLayers.map((tl) => tl.getSource()) as TileWMS[];

    if (!tileSources) {
      return;
    }

    const viewResolution = this.olMapService.map.getView().getResolution();

    const urls = tileSources.map((ts) => {
      return ts.getFeatureInfoUrl(
        event.coordinate,
        viewResolution,
        "EPSG:3857",
        {
          INFO_FORMAT: "application/json",
          FEATURE_COUNT: 50,
        }
      );
    });
    return urls.filter((url) => url != "").map((url) => this.http.get(url));
  }
}
